<template>
    <div>
        <b-button v-show="false" :to="{ name:'create-user'}" class="mb-1 mr-1" variant="relief-primary">
            {{ $t('new') }}
        </b-button>
        <b-button class="mb-1" variant="info" @click="visible = !visible">
            {{ $t('search') }}
        </b-button>
        <custom-filter :filters="filters" :visible="visible" @onSubmit="filters => filter(filters)">
            <template #form="{filters}">
                <b-row>
                    <b-col md="6">
                        <custom-input v-model="filters.name" :name="$t('name')" vid="name"/>
                    </b-col>
                    <b-col md="6">
                        <custom-input v-model="filters.email" :name="$t('Email')" vid="email"/>
                    </b-col>
                </b-row>
            </template>
        </custom-filter>
        <b-card no-body>
            <b-table :busy="status === 'LOADING'" :fields="fields" :items="Object.values(users)" hover responsive>
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle" />
                    </div>
                </template>
                <template #cell(action)="data">
                    <b-dropdown no-caret variant="link">
                        <template #button-content>
                            <feather-icon icon="MoreHorizontalIcon" />
                        </template>
                        <b-dropdown-item v-if="$can('user-edit')" :to="{name: 'edit-user', params:{id: data.item.id}}">
                            <feather-icon icon="Edit2Icon" />
                            {{ $t('edit') }}
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <b-pagination
                v-if="pagination.last_page > 1"
                v-model="pagination.current_page"
                :per-page="pagination.per_page"
                :total-rows="pagination.total"
                align="center"
                class="mt-2"
                @change="paginationCategory"
            />
        </b-card>
    </div>

</template>

<script>
import fields from "./partials/fields";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "Users",

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchUsers()
        })
    },

    data() {
        return {
            fields,
            visible: false,
            filters: {
                name: '',
                email: '',
            }
        }
    },

    computed: {
        ...mapGetters('user', [
            'users', 'status', 'pagination'
        ]),
        ...mapGetters('filter', [
            'filterFields'
        ])
    },

    methods: {
        ...mapActions({
            fetchUsers: 'user/fetch',
        }),
        filter(filters) {
            this.fetchUsers({
                ...filters,
                page: 1
            })
        },
        paginationCategory(page) {
            this.fetchUsers({
                page,
                ...this.filterFields
            })
        }
    }
}
</script>
